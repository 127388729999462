import React from "react"

const TermsOfService = ({ data }) => {
    return (
        <div className="pt-6 pb-14 md:pt-14 md:pb-28 px-3 md:px-8 lg:px-12">
            <div>
                <div className="container text-editor" dangerouslySetInnerHTML={{ __html: data.content }} />
            </div>
        </div>
    )
}

export default TermsOfService
