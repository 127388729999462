import React from "react"
import { graphql } from "gatsby"
import LayoutHeadFoot from "../components/Shared/Layouts/LayoutHeadFoot";
import TermsOfService from "../components/TermsOfServicePage/TermsOfService";
import SEO from "../components/seo";

const TermsService = ({ data }) => {

  const termsData = data.allStrapiTermsOfService.edges[0].node;

  return (
    <>
      <SEO seo={termsData.metaData} />
      <LayoutHeadFoot>
        <TermsOfService data={termsData} />
      </LayoutHeadFoot>
    </>
  )
}

export const query = graphql`
query TermsOfServiceQuery {
    allStrapiTermsOfService {
    edges {
      node {
        metaData {
          metaTitle
          metaDescription
          shareImage {
            localFile {
              publicURL
            }
          }
        }
        content
      }
    }
  }
}`

export default TermsService


