import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';


const CookieBox = () => {
    const [showCookie, setShowCookie] = useState(false)

    useEffect(() => {
        if (!localStorage.getItem('cookieAccept')) {
            setShowCookie(true)
        }
    }, [showCookie])

    const handleAccept = () => {
        localStorage.setItem('cookieAccept', true);
        setShowCookie(false)
    }

    return (
        showCookie && <div className="bg-blue-dark fixed left-0 right-0 bottom-0 z-50">
            <div className="container py-4 md:py-3">
                <div className="flex items-center md:items-start justify-center flex-col md:flex-row">
                    <p className="text-white mb-2 md:mb-0 md:self-center text-sm text-center">
                        We use cookies to provide necessary site functionality and ensure your best experience. By using our website, you agree to our
                        <Link className="underline ml-2" to="/privacy-policy/">Privacy Policy.</Link>
                    </p>
                    <button onClick={handleAccept} className="rounded-lg bg-white text-blue-dark border border-blue-dark px-4 md:px-6 py-1 text-sm ml-0 md:ml-4 flex-shrink-0">
                        OK
                    </button>
                </div>
            </div>
        </div>
    )
}


export default CookieBox;