import React from 'react'
import { useEffect, useState } from "react";

import Footer from '../Footer'
import Header from './Header'
import CookieBox from '../CookieBox';

function useScrollPosition() {
  const [position, setPosition] = useState(0)

  const handleScroll = () => {
     setPosition(window.scrollY)
  };

  useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    return() => {
           window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return position;
}

const LayoutHeadFoot = ({ children }) => {
    const [opacity, setOpacity] = useState(0);
    const position = useScrollPosition()

    useEffect(() => {
        if (position > 10) {
          setOpacity(1);
        } else setOpacity(0);
      }, [position]);

    return (
        <div className="min-h-screen flex flex-col">
            <Header opacity={opacity}/>
            <div>{children}</div>
            <Footer />
            <CookieBox />
        </div>
    )
}

export default LayoutHeadFoot